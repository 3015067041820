<template>
    <div>
        <van-nav-bar class="learning-title" fixed>
            <template #title>
                选课学习
            </template>
            <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="客服" @click="mySchool"/>
                </van-grid>
            </template>
        </van-nav-bar>
        <transition name="van-slide-right" mode="out-in">
            <router-view></router-view>
        </transition>
        <div style="margin-top:6rem">
            <van-tabs v-model:active="active" class="courseList" color="#1989fa" @click="clickTab" >
                <van-tab name="1" title="课程列表">
                    <div v-if="commodityData.length > 0">
                      <van-card class="courseCard"
                                v-for="(item,index) in commodityData"
                                :key="index">
                        <template #title>
                          <div class="van-card__title" :data-id="item.id"  @click="enterLearn(1,item.oid,item.selectCourse)" v-html="item.title">

                          </div>
                        </template>
                        <template #desc>
                          <div class="van-card__desc" :data-id="item.id"  @click="enterLearn(1,item.oid,item.selectCourse)">
                            课程到期时间：{{ item.finishtime }}
                          </div>
                        </template>
                        <template #tags>
                          <van-tag plain round color="#646566" text-color="#646566" :data-id="item.id"  @click="enterLearn(1,item.oid,item.selectCourse)">
                            总学时：
                            <span v-if="item.selectCourse === 0">不少于{{ item.study_time }}学时</span>
                            <span v-else>{{ item.study_time }}学时</span>

                          </van-tag>
                          <van-tag plain round color="#646566" text-color="#646566" :data-id="item.id"  @click="enterLearn(1,item.oid,item.selectCourse)">
                            总时长：{{ item.video_duration }}小时
                          </van-tag>
                        </template>
                        <template #footer>
                          <div style="margin-right: 10rem; height: 3.5rem;">
                            <van-progress
                                :percentage="item.learning ? item.learning : 0"
                                :pivot-text="item.learning ? '已学习：'+item.learning+'%' : '0%'"
                                pivot-color="#888888"
                                stroke-width="8"/>
                          </div>
                          <div style="position: absolute; width: 11rem;right: 1.5rem;top: 12.5rem;">
                            <van-button round size="mini" color="#1989fa" :data-id="item.id" @click="enterLearn(1,item.oid,item.commodity_id,item.selectCourse)">
                              {{ item.selectCourse === 0 ?'选择课程':'进入学习'}}
                            </van-button>
                          </div>
                          <van-grid :column-num="4" class="courseTag">
                            <van-grid-item class="gridTime">
                              <van-cell center :title="item.study_score?item.study_score:0" label="已学学时"/>
                            </van-grid-item>
                            <van-grid-item class="gridTime">
                              <van-cell center :title="item.study_hour?item.study_hour+'h':0" label="学习时长"/>
                            </van-grid-item>
                            <van-grid-item icon="comment-o" text="课程评价" @click="onStudy(1,item.oid,item.selectCourse)"/>
                            <van-grid-item icon="edit" text="在线考试" @click="onCommodity(1,item.oid,item.selectCourse)"/>
                          </van-grid>
                        </template>
                      </van-card>
                    </div>
                    <div v-else>
                        <!-- 无课程的时候显示 -->
                        <van-empty
                                description="暂无报名课程"
                                image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                                image-size="24rem">
                            <van-button round color="#1989fa" size="normal" class="bottom-button" >立即报名</van-button>
                        </van-empty>
                    </div>
                </van-tab>
            </van-tabs>
        </div>

        <van-popup v-model="show">
            <van-image
                    width="30rem"
                    height="30rem"
                    fit="cover"
                    :src="agreementUrl"
            /></van-popup>
    </div>
</template>


<script>
    import {getMyCommodityList} from "@/request/api";
    import { Toast } from 'vant';
    export default {
        name: 'LearningRecord',
        metaInfo() {
            return {
                title: this.title+'-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active:'',
                item: "",
                learnTap: [
                    {
                        text: "看课记录",
                        icon: "n",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-learningrecords.png",
                        link: 'handleClickRecord'
                    },
                    {
                        text: "课程答疑",
                        icon: "w",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-coursequestion.png",
                        link: 'handleClickQuestion'
                    },
                    {
                        text: "我的笔记",
                        icon: "j",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-mynote.png",
                        link: 'handleClickNote'
                    },
                    {
                        text: "听课帮助",
                        icon: "g",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-learninghelp.png",
                        link: 'handleClickHelp'
                    },
                ],
                commodityData: [],
                commodityFreeData: [],
                tab_id:1,
                show: false,
                agreementUrl:''
            }
        },
        methods: {
            mySchool(){
                this.$router.push('/MySchool')
            },
            handler(link) {
                this[link]()
            },
            handleClickRecord() {
                // this.$router.push('/LearningRecord')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                Toast('暂未开启');
            },
            handleClickQuestion() {
                this.$router.push('/CourseQuestion')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                // Toast('暂未开启');
            },
            handleClickNote() {
                this.$router.push('/MyNote')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                // Toast('暂未开启');
            },
            handleClickHelp() {
                // this.$router.push('/index/searchPopup')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                // Toast('暂未开启');
                this.$router.push('/courseHelp')
            },
            enterLearn(type,id,commodity_id,select_course) {
                if(select_course === 0){
                  this.$router.push({
                    name:'LearningListSelect',
                    params:{id,commodity_id},
                    query:{id,commodity_id},
                  })
                }else{
                  this.$router.push({
                    name:'LearningList',
                    params:{id,commodity_id},
                    query:{id,commodity_id},
                  })
                }

            },
            agreement(id,orderId) {
                let that = this;
                courseAgreement({commodityId:id,orderId})
                    .then(res => {
                        if (res.data.code == 1) {
                            // that.agreementUrl = res.data.data.url
                            // that.show = true;
                            // console.log(that.agreementUrl);
                            window.location.href=res.data.data.url;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                        Toast.fail('请求链接错误');
                    })
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },
            clickTab(name,title) {
                this.tab_id = name
                if(this.tab_id == 1) {
                    this.commodity();
                } else {
                    this.commodityFree();
                }
            },
            commodity() {
                let that = this;
                getMyCommodityList()
                    .then(res => {
                        if (res.data.code == 1) {
                            that.commodityData = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            commodityFree() {
                let that = this;
                commodityListFree({type:2})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.commodityFreeData = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            onCommodity(type,id,orderId = '',agreementStatus = '') {
                if(type == 1 && agreementStatus == 0) {
                    //未签署协议
                    Toast.fail('未签署协议');
                    this.agreement(id,orderId);
                    return false;
                }
                //随堂练习
                this.$router.push({
                    name: 'CoursePractice',
                    query: {
                        id
                    }
                })
            },
            onStudy(type,id,orderId = '',agreementStatus = '') {
                if(type == 1 && agreementStatus == 0) {
                    //未签署协议
                    Toast.fail('未签署协议');
                    this.agreement(id,orderId);
                    return false;
                }
                //学习周报
                this.$router.push({
                    name: 'dayEcharts',
                    query: {
                        id
                    }
                })
            },
            onCommodityUpdate(id) {
                //更新进度
                // this.$router.push({
                //     name: 'courseUpdate',
                //     query: {
                //         id
                //     }
                // })
            },
            onExaminationData(type,id,orderId = '',agreementStatus = '') {
                if(type == 1 && agreementStatus == 0) {
                    //未签署协议
                    Toast.fail('未签署协议');
                    this.agreement(id,orderId);
                    return false;
                }
                //备考资料
                this.$router.push({
                    name: 'CourseData',
                    query: {
                        commodityId:id
                    }
                })
            },
            onLearningPlan(type,id,orderId = '',agreementStatus = '') {
                //学习计划
                // this.$router.push({
                //     name: 'learnPlan',
                //     query: {
                //         id
                //     }
                // })
            },
            /**
             *
             * @param type 1收费 2免费
             * @param oid 订单id
             * @param id 商品id
             */
            onCourseAgreement(type,id,orderId = '',agreementStatus = '') {
                //课程协议
                if(type == 1) {

                    // Toast.fail('未签署协议');
                    this.agreement(id,orderId);
                    // this.$router.push({
                    //     name: 'courseAgreement',
                    //     query: {
                    //         oid,
                    //         id,
                    //     }
                    // })
                    //生成小程序二维码 扫码签署协议
                } else {
                    Toast.fail('免费班次不需要签约');
                }

            },
            receive(id) {
                let that = this;
                freeCommodityOrder({id})
                    .then(res => {
                        if (res.data.code == 1) {
                            Toast.success('领取成功');
                            that.$router.push({
                                name:'LearningList',
                                params:{
                                    id
                                }
                            })
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            }
        },
        created() {
            let that = this;
            if(that.tab_id == 2) {
                this.commodityFree();
            } else {
                this.commodity();
            }
            this.title = this.getBranchCompany()
        }
    }
</script>

<style lang="less">
    
    .learning-title {
        z-index: 99;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 500 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.6rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }


    .learn-tap {
        height: 10rem;
        margin-top: 6rem;
    }

    .learn-tap .van-grid-item .van-icon {
        font-size: 3.6rem;
    }

    .learn-tap .van-grid-item__content::after {
        border-width: 0;
    }

    .learn-tap .van-grid-item__text {
        font-size: 1.6rem;
        margin-top: 0.5rem;
    }

    .courseList {
        padding-bottom: 8rem;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 1;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }


    .courseCard {
        padding: 1.5rem;
        color: #323233;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .courseCard .van-card__thumb {
        width: 15rem;
        height: 10rem;
        margin-right: 1rem;
    }

    .courseCard .van-card__thumb img {
        border-radius: 0.5rem;
    }

    .courseCard .van-card__title {
        max-height: 3.6rem;
        font-weight: 600;
        line-height: 3.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .courseCard .van-card__desc {
        max-height: 3.6rem;
        font-size: 1.6rem;
        line-height: 3.6rem;
    }

    .courseCard .van-card__content {
        min-height: 10rem;
    }

    .preLearn {
        width: 95%;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        font-size: 1.4rem !important;
        border-color: #969799 !important;
        line-height: 2rem !important;
    }

    .courseCard .van-card__footer {
        height: 12rem;
        padding-top: 2rem;
    }

    .courseCard .van-card__footer .van-progress__portion {
        background-color: #e7e9ed;
    }

    .courseCard .van-card__footer .van-progress__pivot {
        width: 12rem;
    }

    .courseTag {
        // height: 15rem;
    }

    .courseTag .van-grid-item__content {
        background: #fafafa;
    }

    .courseTag .van-grid-item__text {
        font-size: 1.6rem;
        margin-top: 0.2rem;
    }

    .courseTag .van-grid-item .van-icon {
        font-size: 2.8rem;
        color: #646566;
        padding-bottom: 0.4rem;
    }

    .courseTag .van-grid-item__content::after {
        border-width: 0;
    }

    .gridTime .van-cell {
        background: #fafafa;
    }

    .gridTime .van-cell .van-cell__title {
        text-align: center;
        color: #646566;
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 0.8rem;
        padding-top: 0.5rem;
    }

    .gridTime .van-cell .van-cell__title .van-cell__label {
        font-size: 1.6rem;
        font-weight: normal;
        margin-top: 1.4rem;
        color: #646566;
    }

    .van-button--mini {
        padding: 0 1rem !important;
    }


</style>
