<template>
    <div>
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">选课</van-button>
            </template>
            <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="客服"/>
                </van-grid>
            </template>
        </van-nav-bar>
        <div class="courseList">
            <div class="courseCell" v-if="list.length > 0">
                <van-checkbox-group v-model="result" @change="onSelectStudyTime">
                    <van-cell-group :border="false">
                        <van-cell
                        center
                        v-for="(item, index) in list"
                        clickable
                        :key="item.id"
                        @click="toggle(index)"
                        >
                        <template #icon>
                            <van-icon v-if="item.course_type === 2" size="4rem" style="margin-right: 0.4rem;" name="https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-zhuanye.png"/>
                            <van-icon v-else size="4rem" style="margin-right: 0.4rem;" name="https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-gong.png"/>
                        </template>
                        <template #right-icon>
                            <van-checkbox :name="item.id" ref="checkboxes" />
                        </template>
                        <template #title>
                            <div class="van-cell__title">
                                {{ item.title }}
                            </div>
                        </template>
                        <template #label>
                            <div class="van-cell__label">共{{item.study_time}}学时，时长：{{item.video_duration}}小时</div>
                        </template>
                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
            <!-- </div> -->
            </div>
            <div v-else>
              <van-empty
                  description="暂无课程"
                  image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                  image-size="24rem">
              </van-empty>
            </div>
        </div>
        <van-cell center class="lastCell01">
            <!-- <template #icon>
                <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon_success_build.png"/>
            </template> -->
            <template #title>
                <div class="van-cell__title">
                    已选课程明细(不少于{{ commodity.study_time}}学时)
                </div>
            </template>
            <template #label>
                <van-tag type="primary" v-if="commodity.course_type === 2">专业课：{{ select_study_time }}学时</van-tag>
                <van-tag type="success" v-else>公需课：{{ select_study_time }}学时</van-tag>
            </template>
            <template #right-icon>
                <van-button round type="info" @click="onSubmitCourse()">提交</van-button>
            </template>
        </van-cell>
    </div>
</template>

<script>
import {addUserSelectCourse, getShopCourseList} from "@/request/api";

    // let commodityId;
    export default {
        metaInfo() {
            return {
                title: this.title+'-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                lastStudy: [],
                courseShow: true,
                commodityId: '',
                commodity:{},
                list: [],
                result: [],
                index_list: [],
                select_study_time:0,
            }
        },
        methods: {
            toggle(index) {
                this.$refs.checkboxes[index].toggle();
                let idx = this.index_list.indexOf(index);
                if(idx === -1){
                  this.index_list.push(index);
                }else{
                  this.index_list.splice(idx, 1);
                }
            },
            course() {
                let commodityId = this.commodityId;
                getShopCourseList({id: commodityId})
                    .then(res => {
                        if (res.data.code == 1) {
                          this.list = res.data.data.data;
                          this.commodity = res.data.data.commodity;
                        }else{
                          this.$toast.fail(res.data.msg)
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                        this.$toast.fail('请求错误')
                    })
            },
            onBack() {
                this.$router.replace('/learning');
            },
            onSelectStudyTime(){
              let indexList = this.index_list;
              console.log(indexList);
              let len = indexList.length;
              let study_time = 0;
              for (let i = 0; i < len; i++) {
                 let course = this.list[indexList[i]];
                 study_time += parseFloat(course.study_time);
              }
              this.select_study_time  = study_time;
            },
            onSubmitCourse(){
                if(this.commodity.study_time > this.select_study_time){
                  this.$toast.fail('选择学习不能小于'+this.commodity.study_time+'学时')
                  return false;
                }
                let commodityId = this.commodityId;
                addUserSelectCourse({id:this.commodityId,course_ids:this.result})
                    .then(res => {
                      if(res.data.code === 1){
                        this.$toast.success(res.data.msg);
                        this.$router.replace({name:'LearningList',params:{id:commodityId}});
                      }else{
                        this.$toast.fail(res.data.msg)
                      }
                    })
                    .catch(err => {
                      this.$toast.fail('请求错误！')
                    })
            }
        },
        created() {
        },
        mounted() {
          this.commodityId = this.$route.params.id
          this.course();
          this.title = this.getBranchCompany()
        }

    }
</script>

<style lang="less">
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }

    .courseList {
        padding: 6rem 0 8rem 0;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }
    .courseList .van-sticky{
        padding-top: 6rem ;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 0rem 0.5rem 0rem 1rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .courseCell .van-cell {
        padding: 1rem 0;
    }
    .courseCell .van-checkbox-group .van-checkbox {
        border-top: 0rem solid #e7e9ed !important;
    }

    .courseCell .van-cell .van-icon {
        // margin-right: 0.8rem;
        font-size: 2rem;
        // color: #646566;
    }

    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .lastCell01 {
        padding: 0.6rem 1rem !important;
        position: fixed;
        bottom: 0;
        border-top: 0.1rem solid #e3e3e3;
    }

    .lastCell01 .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell01 .van-cell__title {
        line-height: 3rem;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell01 .van-cell__label {
        line-height: 3rem;
    }

    .lastCell01 .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
        margin-right: 0.6rem;
        padding: 0.4rem 0.8rem !important;
    }

</style>
