<template>
    <div>
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">我的课程</van-button>
            </template>
            <!-- <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="听课帮助"/>
                </van-grid>
            </template> -->
        </van-nav-bar>
        <div class="course-box">
          <div v-if="courseData.length>0">
            <div class="courseCell">
              <div v-for="(item2,index2) in courseData" :key="index2">
                <van-cell center is-link v-if="item2.num > 0" :data-id="item2.id" :data-courseware="item2.coursewareId" @click="getCourseWareList(item2.id,item2.coursewareId)">
                  <template #icon>
                    <van-icon name="circle"/>
                  </template>
                  <template #title>
                    <div class="van-cell__title">
                      {{item2.title}}
                    </div>
                  </template>
                  <template #label>
                    <div class="van-cell__label">共{{item2.num}}节课，{{item2.video_duration}}小时</div>
                  </template>
                </van-cell>
                <van-cell center is-link v-else>
                  <template #icon>
                    <van-icon name="circle"/>
                  </template>
                  <template #title>
                    <div class="van-cell__title">
                      {{item2.title}}
                    </div>
                  </template>
                  <template #label>
                    <div class="van-cell__title">
                      课程暂未更新
                    </div>
                  </template>
                </van-cell>
              </div>
            </div>
          </div>
          <!-- 无课程的时候显示 -->
          <van-empty
              v-else
              description="当期课程暂未更新"
              image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
              image-size="24rem">
            <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>
          </van-empty>
        </div>

        <van-cell center class="lastCell" @click="lastStudyDetail" v-if="lastStudy">
            <template #icon>
                <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/last_learning1.png"/>
            </template>
            <template #title>
                <div class="van-cell__title">
                    {{lastStudy.title}}
                </div>
            </template>
            <template #label>
                <van-tag color="#07c160">{{lastStudy.video_title}}</van-tag>
            </template>
            <template #right-icon>
                <van-icon name="guide-o" color="#ffffff" size="3rem"/>
            </template>
        </van-cell>


    </div>
</template>

<script>
    import {getMyCourseList} from "@/request/api";

    // let commodityId;
    export default {
        beforeRouteEnter(to, from, next) {
          next()
        },
        metaInfo() {
            return {
                title: this.title+'-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active: '',
                item: '',
                courseData: [],
                courseFreeData: [],
                lastStudy: [],
                courseShow: true,
                commodity_id:0,
                commodityId: '',
                coursewareId: ''
            }
        },
        methods: {
            course() {
                let that = this;
              let commodityId = this.commodityId;
              let commodity_id = this.commodity_id;
              console.log('commodityId',this.commodityId)
              console.log('commodity_id',this.commodity_id)
                getMyCourseList({id: commodityId,commodity_id})
                    .then(res => {
                        console.log(res.data.data.data)
                        if (res.data.code == 1) {
                            that.courseData = res.data.data.data;
                            that.lastStudy = res.data.data.lastStudy;
                            that.courseShow = true;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            getCourseWareList(id,coursewareId) {
              let commodityId = this.commodity_id;
              let oid = this.commodityId;
                this.$router.push({
                    name:'MyCourseDetail',
                    params:{
                        id,
                        commodityId,
                        coursewareId,
                        oid
                    }
                })
            },
            lastStudyDetail() {
                const {id,commodityId,coursewareId} = this.lastStudy;
                console.log(id);
                console.log(commodityId);
                console.log(coursewareId);
                let oid = this.commodityId;

                this.$router.push({
                    name:'MyCourseDetail',
                    params:{
                        id,
                        commodityId,
                        coursewareId,
                        oid
                    }
                })
            },
            onBack() {
                // this.$router.go(-1)
                // this.$router.back(-1)
                // history.back(-1);
                this.$router.replace('/learning');
            },
        },
        created() {

        },
        mounted() {
          console.log(this.$route.params);
          this.commodityId = this.$route.params.id;
          this.commodity_id = this.$route.params.commodity_id;
          this.course();
          this.title = this.getBranchCompany()
        }

    }
</script>

<style lang="less">
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }

    .courseList {
        padding: 0rem 0 8rem 0;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }
    .courseList .van-sticky{
        padding-top: 6rem ;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 1.5rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .courseCell .van-cell {
        padding: 1rem 0;
    }

    .courseCell .van-cell .van-icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .lastCell {
        padding: 0.6rem 1rem !important;
        background-color: #1989fa;
        position: fixed;
        bottom: 0;
    }

    .lastCell .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell .van-cell__title {
        color: #ffffff;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell .van-cell__label {
        line-height: 3rem;
    }

    .lastCell .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
    }
    .course-box{
      padding: 6rem 0 8rem;
    }

</style>
